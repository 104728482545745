import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
})
export class Page404Component implements OnInit {
  client: any;
  constructor(
    public translate: TranslateService,
    private localStorageServ: LocalStorageService,
    private route: ActivatedRoute
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    // if (this.localStorageServ.getItem('lang')) {
    //   translate.setDefaultLang(this.localStorageServ.getItem('lang'));
    // } else {
    //   navigator.language.includes('en')
    //     ? translate.setDefaultLang('en')
    //     : translate.setDefaultLang('fr');
    // }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (param) => (this.client = param.get('client'))
    );
  }
}
