import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { AuthService } from '../../../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authServ: AuthService,
    private router: Router,
    private localStorageServ: LocalStorageService
  ) {}

  canActivate(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authServ.isLoggedIn()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  /* async canActivate(): Promise<boolean> {
    if (!await this.authServ.checkAuthenticated()) {
      await this.router.navigate(['/']);
      return false;
    }
    return true;
  } */
}
