import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  constructor(private http: HttpClient) {}

  uploadFile(id, file) {
    var formdata = new FormData();
    formdata.append('file', file);
    return new Promise((resolve) => {
      this.http
        .post(environment.Api_Url + `doc/uploadfiles?credit=${id}`, formdata)
        .subscribe(
          (res: any) => {
            //console.log('reeeeeessss', res);
            resolve({ status: true, data: res });
          },
          (err) => {
            //console.log('errrrrrrrrr', err);
            resolve({ status: false, error: err });
          }
        );
    });
  }

  getAllFiles(id) {
    return new Promise((resolve) => {
      this.http.get(environment.Api_Url + `doc/filedb/${id}`).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  downloadFile(id, fileName) {
    return new Promise((resolve) => {
      this.http
        .get(environment.Api_Url + `downloadfiledb/${fileName}?id=${id}`)
        .subscribe(
          {
            next: (res) => {
              resolve({ status: true, data: res });
            },
            error: (err) => {
              resolve({ status: false, error: err });
            },
            complete: () => {
              console.info('complete');
            },
          }
        );
    });
  }

  deleteFile(fileid, creditid) {
    return new Promise((resolve) => {
      this.http
        .delete(
          environment.Api_Url + `doc/deletefile/${fileid}?credit=${creditid}`
        )
        .subscribe(
          {
            next: (res) => {
              resolve({ status: true, data: res });
            },
            error: (err) => {
              resolve({ status: false, error: err });
            },
            complete: () => {
              console.info('complete');
            },
          }
        );
    });
  }

  saveCredit(data) {
    return new Promise((resolve) => {
      this.http.post(environment.Api_Url + 'credit', data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  editCredit(id, data) {
    return new Promise((resolve) => {
      this.http.patch(environment.Api_Url + `credit/${id}`, data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }
  getCreditById(id) {
    return new Promise((resolve) => {
      this.http.get(environment.Api_Url + `credit/${id}`).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }
  getAllCredits(page?, itemsPerPage?, search?, status?) {
    return new Promise((resolve) => {
      this.http
        .get(
          environment.Api_Url +
            `credit/get-all?limit=${itemsPerPage}&page=${page}&search=${search}&status=${status}`
        )
        .subscribe(
          {
            next: (res) => {
              resolve({ status: true, data: res });
            },
            error: (err) => {
              resolve({ status: false, error: err });
            },
            complete: () => {
              console.info('complete');
            },
          }
        );
    });
  }

  deleteCredit(id) {
    return new Promise((resolve) => {
      this.http.delete(environment.Api_Url + `credit/${id}`).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  sendMail(data) {
    return new Promise((resolve) => {
      this.http.post(environment.Api_Url + 'email', data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }
  reclamation(data) {
    return new Promise((resolve) => {
      this.http.post(environment.Api_Url + 'email/reclamation', data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  getFiltredCredits(data) {
    return new Promise((resolve) => {
      this.http.post(environment.Api_Url + 'credit/get-all', data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  getEmailsHistory(id){
    return new Promise((resolve) => {
      this.http.get(environment.Api_Url + `email/get-all/${id}`).subscribe(
        /* (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        } */
        {
        next:(res)=>{
          resolve({ status: true, data: res });
      },
      error:(err)=>{
        resolve({ status: false, error: err });
      },
      complete:()=>{
        console.info('complete')
      }


        }
      );
    })
  }

}
