import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmployeService {
  constructor(private http: HttpClient) {}

  saveEmploye(data) {
    return new Promise((resolve) => {
      this.http.post(environment.Api_Url + 'employee', data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  editEmploye(id, data) {
    return new Promise((resolve) => {
      this.http.patch(environment.Api_Url + `employee/${id}`, data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  getEmployes(page?, itemsPerPage?, search? , accountType?) {
    return new Promise((resolve) => {
      this.http.get(environment.Api_Url + `employee/get-all?limit=${itemsPerPage}&page=${page}&search=${search}&accountType=${accountType}`
      ).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  getOneEmployee(id) {
    return new Promise((resolve) => {
      this.http.get(environment.Api_Url + `employee/${id}`).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }
  addSimulation(id , data) {
    return new Promise((resolve) => {
      this.http.patch(environment.Api_Url + `employee/${id}/simulation` , data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }
  deleteEmploye(id) {
    return new Promise((resolve) => {
      this.http.delete(environment.Api_Url + `employee/${id}`).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }
}
