<div class="page-404">
  <div class="border p-3">
    <p class="center">
      <img
        [routerLink]="['/']"
        src="../../../assets/img/easy.jpg"
        alt="logo"
      />
    </p>
    <h1 class="center">{{ "NOTFOUND.code" | translate }}</h1>
    <p>
      <em>{{ "NOTFOUND.M1" | translate }}</em>
    </p>
    <p>{{ "NOTFOUND.M2" | translate }}</p>
    <p>{{ "NOTFOUND.M3" | translate }}</p>
    <p class="center">
      <a *ngIf="!client" routerLink="/dashboard"
      ><i class="fas fa-home"></i> {{ "HOME.discoverNow" | translate }}
      </a>
      <a *ngIf="client" routerLink="/dashboard/client-dash"
      ><i class="fas fa-home"></i> {{ "HOME.discoverNow" | translate }}
      </a>
    </p>
  </div>
</div>
