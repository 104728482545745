<div class="container-fluid p-0 pb-4 con">
  <div class="topbar">
    <img [routerLink]="['/']" src="../../../assets/img/easy.jpg" alt="logo"/>
    <ul class="menu">
      <li [routerLink]="['/']">Accueil</li>
      <li>
        <button
          mat-button
          [matMenuTriggerFor]="belowMenu"
          #menuTrigger="matMenuTrigger"
          (mouseenter)="openResourceMenu(); recheckIfInMenu = true"
          (mouseout)="closeResourceMenu(); recheckIfInMenu = false"
          class="custom-menu-trigger"
          style="color: #707070;"
        >
          Nos partenaires
        </button>
        <mat-menu
          #belowMenu="matMenu"
          yPosition="below"
          class="mat-mdc-menu-panel"
          [hasBackdrop]="false"
        >
          <span
            (mouseenter)="recheckIfInMenu = true"
            (mouseleave)="closeResourceMenu(); recheckIfInMenu = false"
          >
            <button mat-menu-item [routerLink]="['/banques']">Les banques</button>
            <button mat-menu-item [routerLink]="['/entreprises']">Les entreprises</button>
          </span>
        </mat-menu>
      </li>
      <li [routerLink]="['/procédure']">Comment ça marche ?</li>
      <li [routerLink]="['/contact-nous']">Contactez Nous !</li>
    </ul>
    <div class="toggle-menu" (click)="toggleMenu()">
      <mat-icon>reorder</mat-icon>
    </div>
    <li class="cnx" [routerLink]="['/client']"><u>Se connecter</u></li>
  </div>
  <ul class="mobile-menu" *ngIf="isMenuOpen">
    <li [routerLink]="['/']">Accueil</li>
    <li class="dropdown">
      <button mat-button (click)="togglePartnerMenu()" class="custom-menu-trigger">
        Nos partenaires
        <mat-icon style="height: 18px;">keyboard_arrow_down</mat-icon>

      </button>
      <ul class="partner-menu" *ngIf="isPartnerMenuOpen">
        <li><a [routerLink]="['/banques']" style="color: black">Les banques</a></li>
        <li><a [routerLink]="['/entreprises']" style="color: black">Les entreprises</a></li>
        <li><a [routerLink]="['/contact-nous']" style="color: black">Contactez Nous !</a></li>
      </ul>
    </li>
    <li [routerLink]="['/procédure']">Comment ça marche ?</li>
    <li class="cnx" [routerLink]="['/client']"><u>Se connecter</u></li>
  </ul>
  <div class="main">
    <h3 class="maintext">Voici les étapes à suivre pour effectuer une demande de crédit avec une banque partenaire via
      notre plateforme.</h3>
    <div class="steps" style="margin-top: 7% ; ">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              1- Définir votre besoin financier
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p style="font-size: 20px"> Avant de commencer le processus de demande de crédit, vous êtes invités à définir
            votre besoin
            . Quels crédits souhaitez-vous ? Avez-vous un prêt en cours ? Évaluez vos objectifs financiers.</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel style="margin-top: 2%">
          <mat-expansion-panel-header>
            <mat-panel-title>
              2- Faire une simulation de crédit
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p style="font-size: 20px"> Utilisez gratuitement le simulateur d'éligibilité de crédit mis à disposition par
            notre plateforme pour avoir une idée précise de votre situation financière.
            Cela vous permettra d'estimer votre capacité pour payer le montant des mensualités et la durée convenable
            pour votre prêt.</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel style="margin-top: 2%">
          <mat-expansion-panel-header>
            <mat-panel-title>
              3- Monter le dossier de rachat de crédit
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p style="font-size: 20px"> Une fois que vous avez choisi le crédit qui vous convient, nous vous assistons
            pour monter votre dossier.
            Notre plateforme vous fournira la liste des pièces justificatives nécessaires, telles que vos relevés de
            compte, vos justificatifs de revenus, vos contrats de prêt en cours, etc...</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel style="margin-top: 2% ; width: 100%">
          <mat-expansion-panel-header style=" width: 100%;">
            <mat-panel-title>
              4- Réception de la notification d'accord du crédit
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p style="font-size: 20px">Une fois que vous avez reçu un accord de la part de notre banque partenaire et que
            vous êtes satisfait de l'offre de votre crédit, vous pourrez signer la notification d'accord et poursuivre
            les démarches avec la banque afin de finaliser la demande de crédit et recevoir les fonds.
          <p>
          <p style="font-size: 20px">  Dans le cas d'une opération de rachat de crédit une fois que l'offre de prêt est
          signée, le processus de rachat sera finalisé et les fonds du nouveau prêt seront utilisés pour rembourser vos
          anciens crédits.</p>
          <p style="font-size: 20px"> Notre equipe vous contactera pour s'assurer que vous avez bien saisi tous
            les termes de votre engagement avec la banque partenaire.</p>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion>
        <mat-expansion-panel style="margin-top: 2%">
          <mat-expansion-panel-header>
            <mat-panel-title>
              5- Souscrire une assurance emprunteur (facultatif)
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p style="font-size: 20px"> Lors d'une demande de crédit, vous aurez la possibilité de souscrire une assurance
            de prêt via notre plateforme qui vous oriente vers l'un de nos assureurs partenaires pour bénéficier d'une
            tarification préferentielle.</p>
        </mat-expansion-panel>
      </mat-accordion>
  </div>

    <div class="secondtitle">
    Il est important de rester en contact avec le courtier tout au long du processus et de poser des questions
    si vous avez des doutes ou des préoccupations
  </div>
  </div>
</div>
