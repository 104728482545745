import {Component} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {CompanyService} from "../../services/company.service";
import {EmployeService} from "../../services/employe.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent {
  form: FormGroup;
  listEntreprises: any = [];
  banquesList: any = [];
  constructor(
    private companyService: CompanyService,
    private EmpService: EmployeService,
    private route: Router,
  ) {
  }

  async ngOnInit() {
    this.getAllCompnaies();
    this.getAllBanks();
    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required,Validators.minLength(3),Validators.pattern('^[A-Za-z]+$')]),
      lastName: new FormControl('', [Validators.required,Validators.minLength(3),Validators.pattern('^[A-Za-z]+$')]),
      autre: new FormControl('',),
      email: new FormControl('',),
      phoneNumber: new FormControl('',[Validators.required,Validators.minLength(8), Validators.maxLength(8),Validators.pattern('[0-9]{0,8}')]),

      company: new FormControl('', [Validators.required]),
      matricule: new FormControl('', [Validators.required,Validators.minLength(8), Validators.maxLength(8),Validators.pattern('[0-9]{0,8}')]),
      date_naissance: new FormControl('', ),
      documentType: new FormControl('cin', ),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      cin: new FormControl('',[Validators.required,Validators.minLength(8), Validators.maxLength(8),Validators.pattern('[0-9]{0,8}')]),
      passport: new FormControl('',),
      bank: new FormControl('', [Validators.required]),
      createdBy: new UntypedFormControl('',),
    });
  }

  getAllCompnaies() {
    this.companyService.getCompanies().then((res: any) => {
      console.log('all compnaies: ', res);
      if (res.status) {
        this.listEntreprises = res.data.results.reverse();
      }
    });
  }

  getAllBanks() {
    this.companyService.getBanks().then((res: any) => {
      console.log('all banks: ', res);
      if (res.status) {
        this.banquesList = res.data.results.reverse();
      }
    });
  }

  register() {
    const controlNames = {
      matricule: 'Matricule',
      password: 'Mot de passe',
      phoneNumber: 'Numéro de téléphone',
      firstName: 'Nom',
      lastName: 'Prénom',
      company: 'Entreprise',
      email: 'Email',
      bank: 'Banque',
      cin: 'Cin'
    };
    this.form.patchValue({createdBy: 'USER'});
    let isFormIncomplete = true;
    for (const controlName in this.form.controls) {
      const control = this.form.get(controlName);
      if (control.value !== null && control.value !== '') {
        isFormIncomplete = false;
      }
      if (isFormIncomplete) {
        Swal.mixin({
          customClass: {confirmButton: 'btn btn-success'},
        }).fire({
          text: 'Tous les champs sont requis',
          icon: 'error',
          confirmButtonText: 'Close',
          reverseButtons: true,
        });
        return;
      } else if (control.errors?.required) {
        Swal.mixin({
          customClass: {confirmButton: 'btn btn-success'},
        }).fire({
          text: 'Le champ ' + controlNames[controlName] + ' est requis',
          icon: 'error',
          confirmButtonText: 'Close',
          reverseButtons: true,
        });
        return;
      }
    }

    if (this.form.get(['matricule']).invalid) {

      Swal.mixin({
        customClass: {confirmButton: 'btn btn-success'},
      }).fire({
        // title: 'Are you sure?',
        text: 'Matricule invalide',
        icon: 'error',
        confirmButtonText: 'Close',
        reverseButtons: true,
      });
    }
    if (this.form.get(['password']).invalid) {

      Swal.mixin({
        customClass: {confirmButton: 'btn btn-success'},
      }).fire({
        // title: 'Are you sure?',
        text: 'Mot de passe invalide (minimum 8 caractéres)',
        icon: 'error',
        confirmButtonText: 'Close',
        reverseButtons: true,
      });
    } else if (this.form.get(['email']).invalid) {

      Swal.mixin({
        customClass: {confirmButton: 'btn btn-success'},
      }).fire({
        // title: 'Are you sure?',
        text: 'email invalide',
        icon: 'error',
        confirmButtonText: 'Close',
        reverseButtons: true,
      });
    } else {
      this.EmpService.saveEmploye(this.form.value).then((res: any) => {
        if (res.error) {
          if (res.error.error === 'Email existe déjà') {
            Swal.mixin({
              customClass: {confirmButton: 'btn btn-success'},
            }).fire({
              // title: 'Are you sure?',
              text: res.error.error,
              icon: 'error',

              confirmButtonText: 'Close',

              reverseButtons: true,
            });
          }
          if (res.error.error === 'CIN existe déjà') {
            Swal.mixin({
              customClass: {confirmButton: 'btn btn-success'},
            }).fire({
              // title: 'Are you sure?',
              text: res.error.error,
              icon: 'error',

              confirmButtonText: 'Close',

              reverseButtons: true,
            });
          }
          if (res.error.error === 'Matricule existe déjà') {
            Swal.mixin({
              customClass: {confirmButton: 'btn btn-success'},
            }).fire({
              text: res.error.error,
              icon: 'error',

              confirmButtonText: 'Close',

              reverseButtons: true,
            });
          }
        } else {
          if (res.status) {
            Swal.mixin(
              {
                customClass: {confirmButton: 'btn btn-success'},
              })
              .fire({
                  // title: 'Are you sure?',
                  text: 'Employé ajoutée avec succès',
                  icon: 'success',

                  confirmButtonText: 'Close',

                  reverseButtons: true,
                }
              )
              .then((res) => {
                if (res.isConfirmed || res.isDismissed) {
                  this.route.navigate(['/client']);
                }
              });
          }
        }
      });
    }
  }
}
