<div class="bod">
  <div class="container-fluid bg">
    <div class="form">
      <img
        [routerLink]="['/']"
        src="../../../assets/img/easy.jpg"
        alt="logo"
      />
      <div class="txt mt-4">
        Entrez vos coordonnés d'accès pour pouvoir accéder à votre espace.
      </div>
      <div style="margin-top: 20px; justify-content: center;">
        <form [formGroup]="loginForm">
          <div class="form-group inp">
            <label for="">Entreprise *</label>
            <br>
            <select
              class="inpstyle"
              id=""
              formControlName="company"
            >
              <option value="" disabled></option>
              <option *ngFor="let item of listEntreprises" [value]="item._id">
                {{ item.companyName }}
              </option>
            </select>
          </div>
          <div class="form-group inp">
            <label for="">Matricule *</label>
            <br>
            <input
              class="inpstyle "
              type="text"
              formControlName="matricule"
              maxlength="8"
              placeholder="matricule avec 8 chiffres"
              name=""
            />

          </div>
          <div class="form-group inp ">
            <label for="">Mot de passe *</label>
            <br>
            <input
              class="inpstyle "
              type="password"
              formControlName="password"
              placeholder="Mot de passe"
              name=""
            />

          </div>
        </form>
        <div style="margin-top: -25px">
          <button class="btn submitbtn mt-5"  (click)="login()">Se connecter</button>
          <p style="font-size: 16px ; font-family: var(--Raleway-Bold);"> vous n'avez pas de compte ?
            <a style="cursor: pointer ; color: #137475" [routerLink]="['/inscription']"><u>Inscrivez-vous</u></a></p>
        </div>
      </div>
    </div>
    <div class="ima">
      <img src="../../../assets/img/frame.svg" alt="landing"/>
    </div>
  </div>

</div>
