import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  constructor(private http: HttpClient) {}

  getAllBanks(page?, itemsPerPage?, search?) {
    return new Promise((resolve) => {
      this.http
        .get(
          environment.Api_Url +
            `bank/get-all?limit=${itemsPerPage}&page=${page}&search=${search}`
        )
        .subscribe(
          {
            next: (res) => {
              resolve({ status: true, data: res });
            },
            error: (err) => {
              resolve({ status: false, error: err });
            },
            complete: () => {
              console.info('complete');
            },
          }
        );
    });
  }

  saveBank(data) {
    return new Promise((resolve) => {
      this.http.post(environment.Api_Url + 'bank', data).subscribe({
        next: (res) => {
          resolve({ status: true, data: res });
        },
        error: (err) => {
          resolve({ status: false, error: err });
        },
        complete: () => {
          console.info('complete');
        },
      });
    });
  }

  editBank(id, data) {
    return new Promise((resolve) => {
      this.http.patch(environment.Api_Url + `bank/${id}`, data).subscribe({
        next: (res) => {
          resolve({ status: true, data: res });
        },
        error: (err) => {
          resolve({ status: false, error: err });
        },
        complete: () => {
          console.info('complete');
        },
      });
    });
  }

  deleteBank(id) {
    return new Promise((resolve) => {
      this.http.delete(environment.Api_Url + `bank/${id}`).subscribe({
        next: (res) => {
          resolve({ status: true, data: res });
        },
        error: (err) => {
          resolve({ status: false, error: err });
        },
        complete: () => {
          console.info('complete');
        },
      });
    });
  }

  getTmm(){
    return new Promise((resolve) => {
      this.http
        .get(
          environment.Api_Url +
            `tmm/get-all`
        )
        .subscribe(
          {
            next: (res) => {
              resolve({ status: true, data: res });
            },
            error: (err) => {
              resolve({ status: false, error: err });
            },
            complete: () => {
              console.info('complete');
            },
          }
        );
    });
  }

  editTmm(id, data) {
    return new Promise((resolve) => {
      this.http.patch(environment.Api_Url + `tmm/${id}`, data).subscribe({
        next: (res) => {
          resolve({ status: true, data: res });
        },
        error: (err) => {
          resolve({ status: false, error: err });
        },
        complete: () => {
          console.info('complete');
        },
      });
    });
  }
}
