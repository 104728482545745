import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  saveCompany(data) {
    return new Promise((resolve) => {
      this.http.post(environment.Api_Url + 'company', data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  editCompany(id, data) {
    return new Promise((resolve) => {
      this.http.patch(environment.Api_Url + `company/${id}`, data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }
  getCompany(id) {
    return new Promise((resolve) => {
      this.http.get(environment.Api_Url + `company/${id}`).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  getCompanies() {
    return new Promise((resolve) => {
      this.http.get(environment.Api_Url + 'company/get-all').subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }
  getBanks() {
    return new Promise((resolve) => {
      this.http.get(environment.Api_Url + 'bank/get-all').subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  deleteCompany(id) {
    return new Promise((resolve) => {
      this.http.delete(environment.Api_Url + `company/${id}`).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }
}
