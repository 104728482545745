import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  updatedRequest: any;

  constructor(
    private localStorageServ: LocalStorageService,
    private authServ: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const auth = this.authServ.isLoggedIn();
    const idToken = this.localStorageServ.getItem('id_token');
    if (auth) {
      const cloned = request.clone({
        headers: request.headers.set('Authorization', idToken),
      });

      return next.handle(cloned);
    } else {
      return next.handle(request);
    }
  }
}
