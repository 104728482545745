import {Component, Inject, OnInit} from '@angular/core';
import {Router,} from '@angular/router';
/* import { MapsAPILoader } from '@agm/core'; */
import {DatePipe, DOCUMENT, Location, LocationStrategy, PathLocationStrategy,} from '@angular/common';
import {LocalStorageService} from './services/localstorage/local-storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

  styleUrls: ['./app.component.scss'],
  providers: [
    DatePipe,
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;

  theme$: Observable<boolean>;

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  config: any;
  currentPath: any;
  whiteLabelConfig: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    /* private mapsAPILoader: MapsAPILoader, */
    public datepipe: DatePipe,
    private localStorageServ: LocalStorageService,
    public translate: TranslateService,
    private modalService: NgbModal
  ) {
    let lang = 'fr';
    translate.addLangs(['en', 'fr']);
    if (this.localStorageServ.getItem('lang')) {
      translate.setDefaultLang(this.localStorageServ.getItem('lang'));
      lang = this.localStorageServ.getItem('lang');
    } else {
      if (navigator.language.includes('en')) {
        translate.setDefaultLang('en');
        lang = 'en';
      } else {
        translate.setDefaultLang('fr');
      }
    }
    this.localStorageServ.setItem('lang', lang);
  }

  ngOnInit() {
    // this.recallJsFuntions();
  }

  // recallJsFuntions() {
  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationStart) {
  //       $('#preloaders').fadeIn('slow');
  //     }
  //   });
  //   this.routerSubscription = this.router.events
  //     .pipe(
  //       filter(
  //         (event) =>
  //           event instanceof NavigationEnd || event instanceof NavigationCancel
  //       )
  //     )
  //     .subscribe((event) => {
  //       // $.getScript('assets/js/quickmunch.js');
  //       $('#preloader').fadeOut('slow');
  //       this.location = this.router.url;
  //       if (!(event instanceof NavigationEnd)) {
  //         return;
  //       }
  //       window.scrollTo(0, 0);
  //     });
  // }

  /**
   * changes the style according to the choosen language
   * @param lang : language choosen by user
   */
  changeFontLink(link: string) {
    let headTag = this.document.getElementsByTagName(
      'head'
    )[0] as HTMLHeadElement;

    let newLink = this.document.createElement('link');
    newLink.href = link;
    newLink.rel = 'stylesheet';
    headTag.appendChild(newLink);
  }
}
