<div class="bod">
  <div class="container-fluid bg">
    <div class="form">
      <img
        [routerLink]="['/']"
        src="../../../assets/img/easy.jpg"
        alt="logo"
      />
      <div class="txt mt-4">
        Entrez vos coordonnés pour pouvoir accéder à votre espace.
      </div>
      <div style="margin-top: 20px; justify-content: center;">
        <form [formGroup]="form">
          <div style="display: flex" class="frm">
            <div class="form-group inp">
              <label for="">Nom *</label>
              <br>
              <input
                class="inpstyle "
                type="text"
                formControlName="firstName"
                placeholder="Nom"
                name=""
              />
              <div *ngIf="form.get('firstName').invalid && (form.get('firstName').dirty || form.get('firstName').touched)">
                <div style="color: red;" *ngIf="form.get('firstName').errors.pattern">Veuillez entrer uniquement des caractères alphabétiques.</div>
              </div>
            </div>
            <div class="form-group inp">
              <label for="">Prénom *</label>
              <br>
              <input
                class="inpstyle "
                type="text"
                formControlName="lastName"
                placeholder="Prénom"
                name=""
              />
              <div *ngIf="form.get('lastName').invalid && (form.get('lastName').dirty || form.get('lastName').touched)">
                <div style="color: red;" *ngIf="form.get('lastName').errors.pattern">Veuillez entrer uniquement des caractères alphabétiques.</div>
              </div>
            </div>
          </div>
          <div style="display: flex" class="frm">
            <div class="form-group inp">
              <label for="">Email </label>
              <br>
              <input
                class="inpstyle "
                type="text"
                formControlName="email"
                placeholder="Email"
                name=""
              />
            </div>
            <div class="form-group inp">
              <label for="">Numéro de téléphone *</label>
              <br>
              <input
                class="inpstyle "
                type="text"
                maxlength="8"
                formControlName="phoneNumber"
                placeholder="Numéro de téléphone"
                name=""
              />
              <br><small id="username2-help" *ngIf="(form.controls['phoneNumber'].hasError('pattern') || form.controls['phoneNumber'].hasError('minlength')) && form.controls['phoneNumber'].dirty"
              class="p-error block error" >8 chiffres seulement ! </small>            
      

            </div>
          </div>
          <div style="display: flex ;flex-wrap: wrap;flex-direction: row;" class="frm">
            <div style="width: 50%;" class="form-group inp">
              <label for="">Entreprise *</label>
              <br>
              <select
                class="inpstyle"
                id=""
                formControlName="company"
              >
                <option value="" disabled></option>
                <option *ngFor="let item of listEntreprises" [value]="item._id">
                  {{ item.companyName }}
                </option>
              </select>
            </div>
            <div style="width: 50%;" class="form-group inp " *ngIf="form.value.company=='autre'">
              <label for="">autre *</label>
              <br>
              <input
                class="inpstyle "
                type="text"
                formControlName="autre"
                placeholder="autre"
                name=""
              />
            </div>
            <div style="width: 50%;" class="form-group inp">
              <label for="">Votre banque *</label>
              <br>
              <select
                class="inpstyle"
                formControlName="bank"
              >
                <option value="" disabled></option>
                <option *ngFor="let item of banquesList" [value]="item._id">
                  {{ item.bankName }}
                </option>
              </select>
            </div>
            <div style="width: 50%;" class="form-group inp">
              <label for="">Date de naissance *</label>
              <br>
              <input
                class="inpstyle "
                type="date"
                formControlName="date_naissance"
                placeholder="date naissance"
                name=""
              />
            </div>
          </div>
          
          <div style="display: flex; flex-direction: column; justify-content: space-between; width: 100%;" class="frm">
            <mat-radio-group   aria-labelledby="example-radio-group-label"
            class="example-radio-group"  formControlName="documentType"  style="display: flex; flex-direction: row;align-items: center;justify-content: baseline;">
              <mat-radio-button style="width: 50%;" value="cin"><p >CIN</p></mat-radio-button>
              <mat-radio-button style="width: 50%;"  value="passport"><p>Passport</p></mat-radio-button>
            </mat-radio-group>
         </div>
          <div style="display: flex" class="frm">
            <div class="form-group inp " *ngIf="form.value.documentType=='cin'">
              <label for="">Cin *</label>
              <br>
              <input
                class="inpstyle "
                type="text"
                formControlName="cin"
                placeholder="Cin"
                maxlength="8"
                name=""
              />
              <br><small id="username2-help" *ngIf="(form.controls['cin'].hasError('pattern') || form.controls['cin'].hasError('minlength')) && form.controls['cin'].dirty"
              class="p-error block error" >8 chiffres seulement ! </small>    
            </div>
            <div class="form-group inp " *ngIf="form.value.documentType=='passport'">
              <label for="">Passprot *</label>
              <br>
              <input
                class="inpstyle "
                type="text"
                formControlName="passport"
                placeholder="Passport"
                name=""
              />
            </div>
            <div class="form-group inp">
              <label for="">Matricule </label>
              <br>
              <input
                class="inpstyle "
                type="text"
                placeholder="Matricule"
                formControlName="matricule"
                name=""
                maxlength="8"
              />
            </div>
          </div>
          <div class="form-group inp ">
            <label for="">Mot de passe *</label>
            <br>
            <input
              class="inpstyle local"
              type="password"
              formControlName="password"
              placeholder="Mot de passe"
              name=""
              id="password"
            />

          </div>
        </form>
        <div style="margin-top: -25px">
          <button class="btn submitbtn mt-5" (click)="register()" [disabled]="form.invalid">S'inscrire</button>
          <p style="font-size: 16px ; font-family: var(--Raleway-Bold);"> vous avez un compte ?
            <a style="cursor: pointer ; color: #137475" [routerLink]="['/client']"><u>Connectez-vous</u></a></p>
        </div>
      </div>
    </div>
    <div class="ima">
      <img src="../../../assets/img/frame.svg" alt="landing"/>
    </div>
  </div>

</div>

