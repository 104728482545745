<div class="container-fluid p-0 pb-4 con">
  <div class="topbar">
    <img [routerLink]="['/']" src="../../../assets/img/easy.jpg" alt="logo"/>
    <ul class="menu">
      <li [routerLink]="['/']">Accueil</li>
      <li>
        <button
          mat-button
          [matMenuTriggerFor]="belowMenu"
          #menuTrigger="matMenuTrigger"
          (mouseenter)="openResourceMenu(); recheckIfInMenu = true"
          (mouseout)="closeResourceMenu(); recheckIfInMenu = false"
          class="custom-menu-trigger"
          style="color: #707070;"
        >
          Nos partenaires
        </button>
        <mat-menu
          #belowMenu="matMenu"
          yPosition="below"
          class="mat-mdc-menu-panel"
          [hasBackdrop]="false"
        >
          <span
            (mouseenter)="recheckIfInMenu = true"
            (mouseleave)="closeResourceMenu(); recheckIfInMenu = false"
          >
            <button mat-menu-item [routerLink]="['/banques']">Les banques</button>
            <button mat-menu-item [routerLink]="['/entreprises']">Les entreprises</button>
          </span>
        </mat-menu>
      </li>
      <li [routerLink]="['/procédure']">Comment ça marche ?</li>
      <li [routerLink]="['/contact-nous']">Contactez Nous !</li>
    </ul>
    <div class="toggle-menu" (click)="toggleMenu()">
      <mat-icon>reorder</mat-icon>
    </div>
    <li class="cnx" [routerLink]="['/client']"><u>Se connecter</u></li>
  </div>
  <ul class="mobile-menu" *ngIf="isMenuOpen">
    <li [routerLink]="['/']">Accueil</li>
    <li class="dropdown">
      <button mat-button (click)="togglePartnerMenu()" class="custom-menu-trigger">
        Nos partenaires
        <mat-icon style="height: 18px;">keyboard_arrow_down</mat-icon>

      </button>
      <ul class="partner-menu" *ngIf="isPartnerMenuOpen">
        <li><a [routerLink]="['/banques']" style="color: black">Les banques</a></li>
        <li><a [routerLink]="['/entreprises']" style="color: black">Les entreprises</a></li>
        <li><a [routerLink]="['/contact-nous']" style="color: black">Contactez Nous !</a></li>
      </ul>
    </li>
    <li [routerLink]="['/procédure']">Comment ça marche ?</li>
    <li class="cnx" [routerLink]="['/client']"><u>Se connecter</u></li>
  </ul>
  <div style="text-align: center; margin-top: 80px">
  <span style="   font-size: 46px;
        font-family: var(--font-family-bold);
        color: #292929; margin-top: 10px;">Liste des entreprises</span>
  </div>
  <div style="display: flex; flex-wrap: wrap ;justify-content: center">
    <div class="card mr-4 ml-4 mt-5" style="width: 320px;height: 300px;border:solid 2px #000;border-radius: 10px">
      <img style="margin-top:20px ;height: 220px" src="./assets/img/leoni.jpeg" class="card-img-top"/>
      <div class="card-body" style="text-align: center">
        <h6 style="padding-top: 0px; font-size: 24px">Leoni</h6>
      </div>
    </div>
  </div>
